require('es6-promise').polyfill();
import 'nodelist-foreach-polyfill';

import { tns } from "../node_modules/tiny-slider/src/tiny-slider"
import tabs from './modules/tabs';
import menu from './modules/menu';
import gridSlider from './modules/grid-slider';
import modal from './modules/modal';

window.addEventListener('DOMContentLoaded', () => {
  try {
    tabs('.types-head', '.tabcontent', '.types-headers', 'types-head_active');
  } catch (error) {}
    menu('.header__burger', '.menu', 'active');
  try {
    gridSlider();
  } catch (error) {}
  modal('[data-modal]', '.modal');

  // sliders
  try {
    const sliderTwo = tns({
      container: '.types-child-slider',
      items: 1,
      slideBy: 1, 
      autoplay: false,
      mouseDrag: true,
      controls: true,
      gutter: 30,
      nav: false,
      controlsContainer: "#customize-controls",
      responsive: {
        768: {
          items: 2,
          gutter: 0
        }
      }  
    });
  } catch (error) {}
  try {
    const sliderLogo = tns({
      container: '.about__slider',
      items: 1,
      slideBy: 1, 
      autoplay: false,
      mouseDrag: true,
      controls: true,
      nav: false,
      controlsContainer: "#customize-controls-logo",
      responsive: {
        1024: {
          items: 4,
        }
      }  
    });
  } catch (error) {}
  try {
    const slider = tns({
      container: '.promo-slider',
      items: 1,
      slideBy: 1, 
      autoplay: false,
      mouseDrag: true,
      controls: true,
      nav: false,
      controlsContainer: "#customize-controls",
      responsive: {
        1024: {
          items: 3,
          gutter: 30,
        }
      }  
    });
  } catch (error) {}
  try {
    const phone = document.querySelectorAll('.header__phone aside'),
          stepsPhone = document.querySelectorAll('.steps__card__text-phone strong'),
          footerPhone = document.querySelectorAll('.contact-col-phone strong');

    function getPhone(number) {
      number.forEach(item => {
        stylePhone(item);
      });
    }
    getPhone(phone);
    getPhone(stepsPhone);
    getPhone(footerPhone);
    function stylePhone(phone) {
      const first = phone.querySelector('span').innerHTML.substr(0,7);
      const second = phone.querySelector('span').innerHTML.substr(7);

      phone.innerHTML = `<span class="hidden">${first}</span> ${second}`;
    }
  } catch (error) {}
  
});  