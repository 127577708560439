// menu

function menuOpen(menuSelector, menuContent, activeClass) {
  const menuBtn = document.querySelector(menuSelector),
        menu = document.querySelector(menuContent);
  menuBtn.addEventListener('click', () => {
    menuBtn.classList.toggle(activeClass);
    menu.classList.toggle(activeClass);
    document.body.classList.toggle(activeClass); 
  });
}

export default menuOpen;
 
